




























import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'AppFooterContact',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyInfo (): Record<string, any> {
      return [
        {
          title: this.data.configuration.wb_company_address__text__,
          icon: '$marker'
        },
        {
          title: this.data.configuration.wb_company_email__text__,
          path: `mailto:${this.data.configuration.wb_company_email__text__}`,
          icon: '$mail'
        },
        {
          title: this.data.configuration.wb_company_phone__text__,
          path: `tel:${this.data.configuration.wb_company_phone__text__}`,
          icon: '$phone'
        }
      ]
    }
  },
  methods: {
    generatePathCompanyAddress (): string | null {
      const companyAddress = this.appConfiguration.companyAddress
      if (!companyAddress) return null
      return navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1 ||
        navigator.platform.indexOf('iPad') !== -1
        ? `http://maps.apple.com/?q=${companyAddress
          .split(' ')
          .join('+')}`
        : `https://google.com/maps/place/${companyAddress
          .split(' ')
          .join('+')}`
    }
  }
})
